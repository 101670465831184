define("mgw/controllers/index", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    deviceservice: Ember.inject.service(),
    sortByName: ['name'],
    searchStr: '',
    sortedProjects: Ember.computed.sort('model.projects', 'sortByName'),
    filteredProjects: Ember.computed('searchStr', 'sortedProjects.@each.stringForSearch', function () {
      var _this = this;

      if (this.get('searchStr').length === 0) return this.get('sortedProjects');
      return this.get('sortedProjects').filter(function (project) {
        return project.get('stringForSearch').includes(_this.get('searchStr').toLowerCase());
      });
    }),
    regularProjects: Ember.computed.filterBy('filteredProjects', 'isRegular'),
    hibernatedProjects: Ember.computed.filterBy('filteredProjects', 'isHibernated'),
    libraryProjects: Ember.computed.filterBy('filteredProjects', 'isLibrary'),
    usingCognitoIdentityProvider: Ember.computed.equal('session.data.authenticated.authenticator', 'authenticator:cognito'),
    notOnPremise: !_environment.default.onPremise,
    userIsStaff: Ember.computed.equal('model.user.role', 'rendra_staff'),
    showCreateNewProjectButton: Ember.computed('usingCognitoIdentityProvider', 'notOnPremise', 'userIsStaff', 'deviceservice.iOS', 'currentUser.customerAdminFor.length', function () {
      return (this.get('usingCognitoIdentityProvider') || this.get('currentUser.customerAdminFor.length')) && (this.get('notOnPremise') || this.get('userIsStaff')) && !this.get('deviceservice').iOS;
    }),
    actions: {
      searchChanged: function searchChanged(value) {
        this.set('searchStr', value);
      }
    }
  });

  _exports.default = _default;
});