define("mgw/instance-initializers/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var remaps = {
    'de-at': 'de',
    'de-ch': 'de',
    'de-de': 'de',
    'de-li': 'de',
    'de-lu': 'de',
    'es-ar': 'es',
    'es-bo': 'es',
    'es-cl': 'es',
    'es-co': 'es',
    'es-cr': 'es',
    'es-do': 'es',
    'es-ec': 'es',
    'es-es': 'es',
    'es-gt': 'es',
    'es-hc': 'es',
    'es-hn': 'es',
    'es-mx': 'es',
    'es-ni': 'es',
    'es-pa': 'es',
    'es-pe': 'es',
    'es-pr': 'es',
    'es-py': 'es',
    'es-sv': 'es',
    'es-uy': 'es',
    'es-ve': 'es',
    'fr-be': 'fr',
    'fr-ca': 'fr',
    'fr-ch': 'fr',
    'fr-fr': 'fr',
    'fr-lu': 'fr',
    'fr-mc': 'fr',
    'ja-jp': 'ja',
    'nb-no': 'nb',
    'nn-no': 'nb',
    'se-no': 'nb',
    'pl-pl': 'pl',
    'se-se': 'sv',
    'sv-fi': 'sv',
    'sv-se': 'sv',
    'no': 'nb',
    'zh-cn': 'zh',
    'zh-hk': 'zh',
    'zh-mo': 'zh',
    'zh-sg': 'zh'
  };

  function calculateLocale(locales) {
    // whatever you do to pick a locale for the user:
    var language = navigator.languages && navigator.languages[0] || navigator.language || navigator.userLanguage;
    language = language.toLowerCase();

    if (remaps.hasOwnProperty(language)) {
      language = remaps[language];
    }

    return locales.includes(language.toLowerCase()) ? [language, 'en'] : 'en';
  }

  var _default = {
    name: 'intl',
    after: 'centrallog',
    initialize: function initialize(container) {
      var intl = container.lookup('service:intl');
      intl.set('locale', calculateLocale(intl.get('locales')));
    }
  };
  _exports.default = _default;
});