define("mgw/authenticators/cognito-federation", ["exports", "mgw/authenticators/jwt"], function (_exports, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    cognito: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    authenticate: function authenticate(code, state) {
      return this.cognito.loginWithCode(code, state).then(function (res) {
        res.provider = state;
        return res;
      });
    },
    restore: function restore(data) {
      return Ember.RSVP.resolve(data);
    },
    invalidate: function invalidate() {
      return this.currentUser.unload();
    }
  });

  _exports.default = _default;
});