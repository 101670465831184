define("mgw/templates/mfa-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aovYi0zE",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[24,0,\"mgw__content mgw__content--white\"],[4,[38,1],[[32,0],\"confirmRemoval\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  This removes the mfa setup for your account. Proceed?\\n  \"],[10,\"button\"],[15,\"disabled\",[34,2]],[14,0,\"btn mgw__form__btn--submit mgw__form__btn--standalone flex\"],[14,4,\"submit\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"one-quarter one-quarter--login\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"submit\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,5],[[35,4],\"code\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"padding-11\"],[12],[1,[30,[36,0],[\"invalidCode\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"p\"],[12],[6,[37,6],null,[[\"class\",\"route\"],[\"btn\",\"index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"cancel\"],null]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"isPerformingRequest\",\"if\",\"error\",\"eq\",\"link-to\"]}",
    "meta": {
      "moduleName": "mgw/templates/mfa-remove.hbs"
    }
  });

  _exports.default = _default;
});