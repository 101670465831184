define("mgw/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "mgw/misc/utils"], function (_exports, _authenticatedRouteMixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    deviceservice: Ember.inject.service(),

    /* eslint-disable-next-line no-unused-vars */
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments); // To let the Auth mixin call its beforeModel()
      // sessionAuthenticated is called before beforeModel, but the promise it
      // fires off cannot be waited on. So to ensure we actually wait for the user
      // to be loaded, we assign the promise a name within the service and wait
      // for it there.


      return this.get("currentUser.promise").then(function () {
        if (!_this.get('currentUser.user.id')) {
          // User is authenticated but does not have a user record.
          // Probably because user is logged in using SSO, but haven't created or linked to a
          // StreamBIM user record yet. Best to log out to get back to the federation callback flow.
          _this.get("currentUser.session").invalidate();
        }

        if (_this.get("currentUser.user.mustUpdateProfile") || _this.get("currentUser.user.mustUpdatePassword")) {
          return _this.transitionTo("user-profile");
        } // goto next url if it's set and is a good url


        var nextUrl = _this.session.next;

        if (nextUrl && _utils.default.isSafeNextUrl(nextUrl)) {
          _this.eventservice.stopSession(); // Using run.later here as it appears to be a race condition on Safari between persisting localStorage and forwarding


          var delay = 1000;

          if (_this.get('deviceservice').iOS || _this.get('deviceservice').safari) {
            delay = 3000;
          }

          Ember.run.later(function () {
            window.location.href = nextUrl;
          }, delay);
        }
      });
    },
    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.findRecord("user", this.get("currentUser.user.id")),
        projects: this.store.query("project-link", {
          filter: {
            active: true
          }
        })
      });
    },
    actions: {
      openProject: function openProject(project) {
        this.eventservice.stopSession();
        window.location.href = "https://" + project.get("projectUri");
      }
    }
  });

  _exports.default = _default;
});