define("mgw/services/deviceservice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    iOS: window.bowser.ios || /cordova/.test(window.navigator.userAgent) && !window.bowser.android,
    android: window.bowser.android,
    safari: window.bowser.safari,
    screenOrientationType: window.innerWidth > window.innerHeight ? 'landscape-primary' : 'portrait-primary',
    onNative: Ember.computed('iOS', 'android', function () {
      return this.get('iOS') || this.get('android') || /cordova/.test(window.navigator.userAgent.toLowerCase());
    }),
    touchEnabled: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
    mobile: Ember.computed('windowWidth', 'windowHeight', 'touchEnabled', function () {
      return this.get('windowWidth') < 700 || this.get('windowHeight') < 600 && this.get('touchEnabled');
    }),
    tablet: Ember.computed('mobile', 'touchEnabled', function () {
      return !this.get('mobile') && this.get('touchEnabled');
    }),
    desktop: Ember.computed('mobile', 'tablet', function () {
      return !this.get('mobile') && !this.get('tablet');
    }),
    device: Ember.computed('mobile', 'tablet', function () {
      if (this.get('mobile')) return 'mobile';else if (this.get('tablet')) return 'tablet';else return 'desktop';
    }),
    notMobile: Ember.computed.not('mobile'),
    portrait: Ember.computed('screenOrientationType', function () {
      return this.get('screenOrientationType') === 'portrait-primary' || this.get('screenOrientationType') === 'portrait-secondary';
    }),
    landscape: Ember.computed.not('portrait'),
    mobileLandscape: Ember.computed.and('mobile', 'landscape'),
    mobilePortrait: Ember.computed.and('mobile', 'portrait')
  });

  _exports.default = _default;
});